import React from 'react';
import '../App.css';

const Contact = () => (
  <section className="contact">
    <div className="container">
      <div className="contact-details">
        <p>Phone: +447737660465</p>
        <p>Email: hbarcc@gmail.com</p>
      </div>
    </div>
  </section>
);

export default Contact;
